import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { routes as appRoutes } from '../../config';

const Footer = () => (
	<footer className="Footer">
		<div className="container">
			<span className="Footer__text">
				&copy; Hermes-Theater <em>1997 - {new Date().getFullYear()}</em>
			</span>{' '}
			<Link to={appRoutes.data_protection} className="Footer__link">
				Datenschutz
			</Link>
		</div>
	</footer>
);

export default Footer;
