import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './containers/App/index';
//import registerServiceWorker from './registerServiceWorker';

// Unregister all service workers
if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.getRegistrations()
		.then((registrations) => {
			for (let registration of registrations) {
				registration.unregister();
			}
		})
		.catch(function (err) {
			console.log('Service Worker registration failed: ', err);
		});
}
ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();
