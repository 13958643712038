import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Loader from '../../components/Loader';
import { routes as appAroutes } from '../../config';

const Home = React.lazy(() => import('../Home'));
const About = React.lazy(() => import('../About'));
const Posts = React.lazy(() => import('../Posts'));
const News = React.lazy(() => import('../News'));
const DataProtection = React.lazy(() => import('../DataProtection'));
const Notfound = React.lazy(() => import('../NotFound'));

class App extends Component {
	render() {
		return (
			<Router>
				<div>
					<Header />
					<Suspense fallback={<Loader />}>
						<Switch>
							<Route exact path={appAroutes.home} component={Home} />
							<Route path={appAroutes.news} component={News} />
							<Route path={appAroutes.data_protection} component={DataProtection} />
							<Route path={appAroutes.about_us} component={About} />
							<Route path={appAroutes.productions} component={Posts} />
							<Route path={appAroutes.notfound} component={Notfound} />
							<Route path="*" component={Notfound} />
						</Switch>
					</Suspense>
					<Footer />
				</div>
			</Router>
		);
	}
}

export default App;
