import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { routes as appAroutes } from '../../config';

const Header = () => {
	return (
		<header className="Header">
			<div className="Header__container">
				<div className="Header__wrapper">
					<h1 className="Header__heading">
						<Link to={appAroutes.home}>Hermes-Theater Dettingen</Link>
					</h1>
					<strong className="Header__heading Header__heading--secondary">Das besondere Kellertheater</strong>
				</div>
				<ul className="Header__navigation">
					<li className="Header__navigation-item">
						<Link to={appAroutes.home} className="Header__navigation-link" activeclassname="active">
							Home
						</Link>
					</li>
					<li className="Header__navigation-item">
						<Link to={appAroutes.news} className="Header__navigation-link" activeclassname="active">
							News
						</Link>
					</li>
					<li className="Header__navigation-item">
						<Link to={appAroutes.production} className="Header__navigation-link" activeclassname="active">
							Produktionen
						</Link>
					</li>
					<li className="Header__navigation-item">
						<Link to={appAroutes.about_us} className="Header__navigation-link" activeclassname="active">
							Über uns
						</Link>
					</li>
				</ul>
			</div>
		</header>
	);
};

export default Header;
